import React, { useState } from 'react'
import TextField from '@mui/material/TextField';

const NoOfStudTextfield6 = ({ initialVal1, initialVal2, initialVal3, initialVal4, initialVal5, initialVal6, text12, onChange, onInputChange }) => {

    const [mark1, setMark1] = useState(initialVal1);
    const [mark2, setMark2] = useState(initialVal2);
    const [mark3, setMark3] = useState(initialVal3);
    const [mark4, setMark4] = useState(initialVal4);
    const [mark5, setMark5] = useState(initialVal5);
    const [mark6, setMark6] = useState(initialVal6);


    return (
        <>
            <TextField
                required={(text12 > 1 || text12 <= 7) ? true : false}
                fullWidth
                type="number"
                style={{ width: "100px" }}
                onWheel={(e) => e.target.blur()}
                value={mark1}
                onChange={(e) => setMark1(e.target.value)}
                onBlur={() => {
                    return (
                        onChange(mark1, mark2, mark3, mark4, mark5, mark6),
                        onInputChange
                    )
                }}
                autoComplete='off'
                size='small'
                id="outlined-basic"
                label="1st टक्केवारी"
                variant="outlined"
                margin='dense'
                InputLabelProps={{ style: { fontSize: 14 } }}
            />

            <TextField
                required={(text12 > 1 || text12 <= 7) ? true : false}
                fullWidth
                type="number"
                style={{ width: "100px", marginLeft: '8px' }}
                onWheel={(e) => e.target.blur()}
                value={mark2}
                onChange={(e) => setMark2(e.target.value)}
                onBlur={() => {
                    return (
                        onChange(mark1, mark2, mark3, mark4, mark5, mark6),
                        onInputChange
                    )
                }}
                autoComplete='off'
                size='small'
                id="outlined-basic"
                label="2nd टक्केवारी"
                variant="outlined"
                margin='dense'
                InputLabelProps={{ style: { fontSize: 14 } }}
            />

            <TextField
                required={(text12 > 1 || text12 <= 7) ? true : false}
                fullWidth
                type="number"
                style={{ width: "100px", marginLeft: '8px' }}
                onWheel={(e) => e.target.blur()}
                value={mark3}
                onChange={(e) => setMark3(e.target.value)}
                onBlur={() => {
                    return (
                        onChange(mark1, mark2, mark3, mark4, mark5, mark6),
                        onInputChange
                    )
                }}
                autoComplete='off'
                size='small'
                id="outlined-basic"
                label="3rd टक्केवारी"
                variant="outlined"
                margin='dense'
                InputLabelProps={{ style: { fontSize: 14 } }}
            />

            <TextField
                required={(text12 > 1 || text12 <= 7) ? true : false}
                fullWidth
                type="number"
                style={{ width: "100px", marginLeft: '8px' }}
                onWheel={(e) => e.target.blur()}
                value={mark4}
                onChange={(e) => setMark4(e.target.value)}
                onBlur={() => {
                    return (
                        onChange(mark1, mark2, mark3, mark4, mark5, mark6),
                        onInputChange
                    )
                }}
                autoComplete='off'
                size='small'
                id="outlined-basic"
                label="4th टक्केवारी"
                variant="outlined"
                margin='dense'
                InputLabelProps={{ style: { fontSize: 14 } }}
            />

            <TextField
                required={(text12 > 1 || text12 <= 7) ? true : false}
                fullWidth
                type="number"
                style={{ width: "100px", marginLeft: '8px' }}
                onWheel={(e) => e.target.blur()}
                value={mark5}
                onChange={(e) => setMark5(e.target.value)}
                onBlur={() => {
                    return (
                        onChange(mark1, mark2, mark3, mark4, mark5, mark6),
                        onInputChange
                    )
                }}
                autoComplete='off'
                size='small'
                id="outlined-basic"
                label="5th टक्केवारी"
                variant="outlined"
                margin='dense'
                InputLabelProps={{ style: { fontSize: 14 } }}
            />

            <div className="row">
                <div className="offset-sm-2">
                    <TextField
                        required={(text12 > 1 || text12 <= 7) ? true : false}
                        fullWidth
                        type="number"
                        style={{ width: "100px", marginLeft: '8px' }}
                        onWheel={(e) => e.target.blur()}
                        value={mark6}
                        onChange={(e) => setMark6(e.target.value)}
                        onBlur={() => {
                            return (
                                onChange(mark1, mark2, mark3, mark4, mark5, mark6),
                                onInputChange
                            )
                        }}
                        autoComplete='off'
                        size='small'
                        id="outlined-basic"
                        label="6th टक्केवारी"
                        variant="outlined"
                        margin='dense'
                        InputLabelProps={{ style: { fontSize: 14 } }}
                    />
                </div>
            </div>
        </>
    )
}

export default NoOfStudTextfield6
