import { BaseURL, dBaseURL } from "../../CommonServices/APIURL";

// /localhost:8080/institute/sss/academic/reports/getRankReportTermWise?langId=1&classId=9&divId=36&termId=1&sessionYear=2022&sid=3&bid=10&medium=1&subjectList= ,viewType: 1 
export const printTermWiseStudentRank = BaseURL + "academic/reports/getRankReportTermWise";

// /getRankReportTermAndExamWise
//examId
export const printExamWiseStudentRank = BaseURL + "academic/reports/getRankReportTermAndExamWise";



//Class Merit List APIURL
//class merit list term wise
//localhost:8080/institute/sss/academic/reports/getRankReportTermWiseAndClassId?langId=1&classId=6&termId=1&rankCount=5&sessionYear=2022&sid=3&bid=1&medium=1
export const printTermWiseClassMeritList = BaseURL + "academic/reports/getRankReportTermWiseAndClassId";

//class merit list exam wise
//localhost:8080/institute/sss/academic/reports/getRankReportTermAndExamWiseAndClassId?langId=1&classId=6&termId=1&rankCount=5&sessionYear=2022&sid=3&bid=1&medium=1&examId=1
export const printExamWiseClassMeritList = BaseURL + "academic/reports/getRankReportTermAndExamWiseAndClassId";

//get Subject list class and term wise
//getSubjectListForExamByClassId
export const getsubjectListByClass = BaseURL + "academic/reports/getSubjectListForExamByClassId";



// ----sub merit list term wise----
// localhost:8080/institute/sss/academic/reports/getRankReportTermWiseAndClassIdAndSubjectId?langId=1&classId=6&termId=1&rankCount=5&sessionYear=2022&sid=3&bid=1&medium=1
// {
//     "id": 1,
//     "subjectNameMr": "मराठी",
//     "subjectNameEn": "Marathi",
//     "viewType": 1,
//     "markOrGrade": 1
// }
export const printTermWiseSubMeritList = BaseURL + "academic/reports/getRankReportTermWiseAndClassIdAndSubjectId";

// ----sub merit list exam wise----
// localhost:8080/institute/sss/academic/reports/getRankReportTermAndExamWiseAndClassIdAndSubjectId?langId=1&classId=6&termId=1&rankCount=5&sessionYear=2022&sid=3&bid=1&medium=1&examId=1
// {
//     "id": 1,
//     "subjectNameMr": "मराठी",
//     "subjectNameEn": "Marathi",
//     "viewType": 1,
//     "markOrGrade": 1
// }
export const printExamWiseSubMeritList = BaseURL + "academic/reports/getRankReportTermAndExamWiseAndClassIdAndSubjectId";


//Term observation Print API
//http://192.168.29.102:8081/institute-sss/institute/sss/academic/reports/generateProgressCardTermObservationReport?langId=1&classId=8&divId=56&termId=1&year=2024&sid=3&bid=1
export const printClassDivWiseTermWiseTermObservationPrint = BaseURL + "academic/reports/generateProgressCardTermObservationReport";

//http://192.168.29.102:8081/institute-sss/institute/sss/academic/reports/generateProgressCardTermObservationReportByRegNo?langId=1&regNo=4730&termId=1&year=2024&sid=3&bid=1
export const printStudentWiseTermWiseTermObservationPrint = BaseURL + "academic/reports/generateProgressCardTermObservationReportByRegNo";
