import React, { useState, useEffect } from 'react';
import TitleLabel from "../../../CommonComponent/TitleLabel";
import axios from 'axios';
import PrintIcon from '@mui/icons-material/Print';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import Loader from '../../../CommonComponent/Loader';
import { getExportedReportAPIURL } from '../../../CommonServices/APIURL';
import { printEndowmentRegister, printPrizeCodeWiseEndowmentRegister } from '../Services/EndowmentReportAPIURL';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },

}));

const EndowmentRegisterReport = () => {
    const classes = useStyles();
    const authUser = useSelector((state) => state.user.value);
    const navigate = useNavigate();
    const titleId = "Endowment Register";

    const [loaderOption, setLoaderOption] = useState(false);
    const [optionId, setOptionId] = useState(1);
    const [prizeCode, setPrizeCode] = useState('');

    const printEndowmentRegisterReport = async (e) => {
        e.preventDefault();
        setLoaderOption(true);
        if (optionId == 1) {
            await axios.get(`${printEndowmentRegister}`)
                .then((response) => {

                    if (response.data == "NOTFOUND") {
                        //console.log(response.data)   
                        setLoaderOption(false);
                        toast.warn("Data not found.")
                    }
                    else if (response.data != null) {
                        // console.log(response.data)
                        window.open(`${getExportedReportAPIURL}${response.data}`);
                        setLoaderOption(false);
                    }

                })
                .catch(error => {

                    toast.error("Error Occoured, Try after sometime.");
                    console.log("Endowment Master Report failed: " + error)
                })
        }
        else if (optionId == 2) {
            console.log(`${printPrizeCodeWiseEndowmentRegister}?prizeCode=${prizeCode}`)
            await axios.get(`${printPrizeCodeWiseEndowmentRegister}?prizeCode=${prizeCode}`)
                .then(res => {
                    if (res.data == "NOTFOUND") {
                        setLoaderOption(false);
                        toast.warn("Data not found.")
                    }
                    else {
                        window.open(`${getExportedReportAPIURL}${res.data}`);
                        setLoaderOption(false);
                    }
                }).catch(err => {
                    setLoaderOption(false);
                    console.log("Student approval get err" + err);
                })
        }
    }


    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <div>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '300px' }}>
                            <form onSubmit={printEndowmentRegisterReport}>
                                <div className='row my-2'>
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "10px" }}>Report Option :</label>
                                    <div className='col-sm-3'>
                                        <FormControl fullWidth size='small'>
                                            <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Select Report Option</InputLabel>
                                            <Select
                                                MenuProps={{ classes: { paper: classes.select } }}
                                                size='small'
                                                required
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={optionId}
                                                margin='dense'
                                                label="Select Report Option"
                                                onChange={(e) => {
                                                    setOptionId(e.target.value);
                                                    setPrizeCode('');
                                                }}
                                            >

                                                <MenuItem value={1}>All</MenuItem>
                                                <MenuItem value={2}>Prize Code Wise</MenuItem>

                                            </Select>
                                        </FormControl>

                                    </div>
                                </div>

                                {(() => {
                                    if (optionId == 2) {
                                        return (
                                            <>
                                                <div className="row my-2">
                                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Prize Code :</label>
                                                    <div className='col-sm-3'>
                                                        <TextField
                                                            required
                                                            fullWidth
                                                            type="number"
                                                            autoComplete='off'
                                                            size='small'
                                                            id="outlined-basic"
                                                            label="Prize Code"
                                                            value={prizeCode}
                                                            onChange={(e) => {
                                                                setPrizeCode(e.target.value);
                                                            }}
                                                            variant="outlined"
                                                            margin='dense'
                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                        //inputProps={{ readOnly: true }}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                })()}

                                <div className='row mt-3'>
                                    <div className="col-sm-4 offset-sm-2">
                                        <button
                                            type='submt'
                                            className='btn btn-primary btn-sm mb-2'
                                        //disabled={btnDisabled}
                                        >
                                            <PrintIcon fontSize="small" /> Print
                                        </button>
                                    </div>

                                    {/* <button type="submit" className="btn btn-danger btn-md" style={{ margin: '0px 4px' }} onClick={() => navigate("/Home/")}>Exit</button> */}
                                </div>
                            </form>
                        </div>
                    </div >
            }

            <ToastContainer position="top-right" theme="colored" />
        </>
    )
}

export default EndowmentRegisterReport